/* original file: src\assets\js\sw-init.js */
if ('serviceWorker' in navigator) {
    if ('Cypress' in window) {
        void navigator.serviceWorker.getRegistrations().then(function (registrations) {
            registrations.forEach(function (registration) {
                registration.unregister();
            });
        });
    } else {
        const emailKey = 'email';

        const parse = (json) => {
            try {
                return JSON.parse(json);
            } catch {
                return;
            }
        };

        const postEmail = () => {
            const email = parse(localStorage.getItem(emailKey));
            navigator.serviceWorker.controller?.postMessage(email ?? 'unknown');
        };

        void navigator.serviceWorker.register('/sw.js').then(() => {
            postEmail();
        });

        addEventListener('storage', (event) => {
            if (event.key === emailKey) {
                postEmail();
            }
        });
    }
}
